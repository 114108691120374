import React from 'react';

export type ArtCardProps = {
  imageSrc: string;
  title: string;
  price: number;
  soldOut?: boolean;
  description: string;
  href: string;
  artist: string;
  currency?: string;
}

const ArtCard: React.FC<ArtCardProps> = ({ imageSrc, title, price, description, href, artist, soldOut, currency }) => (
  <a className="flex flex-col p-2 focus:outline-double" href={href} target="_blank" >
    <div className='h-80 flex '>
      <img src={imageSrc} className='m-auto object-contain max-w-full max-h-full'></img>
    </div>

    <div className='flex justify-between flex-col'>
      <h2 className="text-gray-800 mt-2 font-normal">{title}</h2>
      <span className="mb-1 block text-gray-600">{description}</span>
      <span className="mb-1 block text-gray-600">Konstnär: {artist}</span>

      {!soldOut && price ? <span>Pris: {price.toLocaleString('sv-Se', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })} {currency ?? 'SEK'}</span> : (Boolean(soldOut) && <span>Såld</span>)}

      {!soldOut && <span className='inline-flex bg-[#fadf87] items-center justify-center w-min mx-auto px-4 rounded-3xl py-2 gap-3 mt-2'>Köpförfrågan <svg className="h-5" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path></svg></span>}
    </div>

  </a>
)

export default ArtCard