import { useEffect, useState } from 'react';
import './App.css';
import ArtCard, { ArtCardProps } from './components/ArtCard';

type Endpoint = 'Trendigt' | 'Hus & Hem';
const endpoints: Record<Endpoint, string> = {
  "Hus & Hem": "promoted",
  Trendigt: "trending",
}

function App() {
  const [artworks, setArtworks] = useState<ArtCardProps[]>([]);
  const [pageNr, setPageNr] = useState<number>(1);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [endpointSelected, setEndpointSelected] = useState<Endpoint>('Hus & Hem');
  const [disableLoader, setSetDisableLoader] = useState<boolean>(false);

  const updateArtwork = async () => {
    setLoadingStatus(true)

    const request = await fetch(`https://api.artportable.com/api/Discover/artworks/${endpoints[endpointSelected]}?pageSize=12&page=${pageNr}`)


    const data = await request.json();

    const newArtworks = data.map((x: {
      Owner: {
        Surname: string; Name: string;
      }; Id: string; Title: string; Description: string; Price: number; SoldOut: boolean; Currency: string; PrimaryFile: { Name: string; };
    }) => ({
      title: x.Title,
      description: x.Description,
      price: x.Price,
      href: `https://artportable.com/sv/art/${x.Id}`,
      artist: `${x.Owner.Name} ${x.Owner.Surname}`,
      imageSrc: `https://artportableprod.blob.core.windows.net/artportable-prod/images/${x?.PrimaryFile?.Name}`,
      soldOut: x.SoldOut, 
      currency: x.Currency
    }))

    setArtworks([...artworks, ...newArtworks]);
    if (newArtworks.length < 12) {
      setSetDisableLoader(true)
    }
    setPageNr(pageNr + 1)
    setLoadingStatus(false)
  }

  useEffect(
    () => {
      updateArtwork()
    }, [endpointSelected]
  )

  const handleTabChange = (key: Endpoint) => {
    if (key === endpointSelected) {
      return
    }
    setSetDisableLoader(false)
    setEndpointSelected(key)
    setArtworks([]);
    setPageNr(1);
  }

  return (
    <div className="App">
      <div className='flex justify-evenly border-b-2 m-2'>
        {Object.keys(endpoints).map((key) =>
          <button className={`px-3 py-1 ${key == endpointSelected && 'text-white bg-[#3a3a3a] border-[#3a3a3a]'}`} onClick={() => handleTabChange(key as Endpoint)}>{key.toUpperCase()}</button>
        )}
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
        {artworks.map(artwork => <ArtCard {...artwork}></ArtCard>)}
      </div>
      {!disableLoader && <button onClick={loadingStatus ? () => { } : updateArtwork} className='ast-custom-button'>{loadingStatus ? "Laddar..." : "Visa fler"}</button>}
    </div>
  );
}

export default App;
